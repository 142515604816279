import { userService } from '../../helpers/fakebackend/user.service';
import router from '../../router/index';
import api from '@/helpers/api-rest/api.js';

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        userService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    // Logout the user
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },

    async resetPassword({ getters }, { token, email, password, re_password } = {}) {
        console.log( getters);
        console.log("AuthFack");
        // await api.get({
        //     baseURL: process.env.VUE_APP_WEB_URL,
        //     url: '/sanctum/csrf-cookie'
        // });

        await api.post({
            url: 'auth/reset-password',
            data:{
                token:token,
                email:email,
                password:password,
                re_password:re_password,
            }
        });
    },

    async validateResetToken({ getters }, { email, token } = {}) {
        console.log( getters);

        // await api.get({
        //     baseURL: process.env.VUE_APP_WEB_URL,
        //     url: '/sanctum/csrf-cookie'
        // });

        await api.post({
            url: 'auth/validate-reset-token',
            data:{
                token:token,
                email:email
            }
        });
    },
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

