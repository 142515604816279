import router from "../router";
import Vue from "vue";

export default function setup(axios) {
    axios.interceptors.request.use(function (config) {
        const token = localStorage.getItem('authToken');
        config.headers.Authorization = 'Bearer ' + token;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
    axios.interceptors.response.use(
        (response) => {
            return Promise.resolve(response);
        },
        (error) => {
            switch (error.response.status) {
                case 401:
                {
                    router.push({ name: 'logout' });
                    //store.commit('auth/SET_CURRENT_USER', null);
                    //router.push({ name: "login" });
                }
                break;
                case 422:
                {
                    const data = error?.response?.data;
                    const validationErrors = data?.extra[0]?.errors;
                    
                    let errorList = '';
                    Object.entries(validationErrors).forEach((obj) => {
                        if(obj[1][0]){
                            errorList += `<li>${(obj[1][0])}</li>`;
                        }
                    });

                    Vue.swal({
                        position: "top-end",
                        icon: "warning",
                        title: data?.errors[0]?.title,
                        showConfirmButton: false,
                        html:  `<ul>${errorList}</ul>`,
                        timer: 20000
                    });
                }
                break;             
            }

            return Promise.reject(error);
        }
    );
}